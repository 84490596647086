import { Client, Study, VueInstance } from '@/types_and_interfaces'
import AuthService from '@/services/AuthService'
import CookieRepository from '@/services/Repositories/CookieRepository'
import BaseStoreService from '@/services/BaseStoreService'
import NotifyedError from '@/data/NotifyedError'
import EventBus from '@/services/EventBus/EventBus'
import {
  CLIENTS_HISTORY_UPDATED,
  CLIENTS_LOADED,
  CYCLES_LOADED,
  LOCALES_LOADED,
  STUDIES_LOADED,
  SURVEYS_LOADED
} from '@/services/EventBus/AppEvents'
import SuccessMessage from '@/data/SuccessMessage'
import router from '@/router'
import { FEEDBACKX } from '@/data/ViewScopes'
import { correctAttributesSchema } from '@/views/Pages/CycleEdit/attributes/factory'

export default class AccountService {
  private $authService: AuthService

  private SELECTED_STUDY_COOKIE_NAME = '0321cfce0f25'
  private CLIENT_HISTORY_COOKIE_NAME = 'dflgkj54twef'
  public selectedStudy: CookieRepository
  public clientHistory: CookieRepository

  constructor(private vue: VueInstance) {
    this.$authService = vue.$authService || vue.prototype.$authService
    this.selectedStudy = new CookieRepository(this.SELECTED_STUDY_COOKIE_NAME)
    this.clientHistory = new CookieRepository(this.CLIENT_HISTORY_COOKIE_NAME)
  }

  get $storeService(): BaseStoreService {
    return this.vue.$storeService || this.vue.prototype.$storeService
  }

  get accountToken() {
    return this.$authService.tokenService.tokens.account?.value
  }

  get studyToken() {
    return this.$authService?.tokenService?.tokens?.study?.value || null
  }

  get fullAccess() {
    const hasAccountToken = this.$authService.tokenService.tokens.account?.value !== null
    const hasStudyToken = this.$authService?.tokenService?.tokens?.study?.value !== null
    return hasAccountToken && hasStudyToken
  }

  private async loadStudyTokens() {
    try {
      const allStudyTokens = await this.vue.prototype.$api.query('admin_getStudies', null, null, true)
      return allStudyTokens.body
    } catch (e) {
      console.error(e)
      new NotifyedError(
        e as Error,
        'Не удалось загрузить данные об исследованиях. Пожалуйста, обратитесь к вашему менеджеру'
      )
      return []
    }
  }

  private async loadClients() {
    try {
      const clients = await this.vue.prototype.$api.query('admin_getClients', {}, {}, true)
      return clients.body
    } catch (e) {
      console.error(e)
      new NotifyedError(e as Error, 'Не удалось загрузить список клиентов')
      return []
    }
  }

  private async loadCycleAttributes() {
    const cycleId = this.vue.prototype.$storeService.get('getSelectedCycle')?.id
    if (!cycleId) return {}

    try {
      const rawAttributes = await this.vue.prototype.$api.query('admin_getCycleAttributes', null, { cycleId })
      return correctAttributesSchema(rawAttributes?.body.attributes)
    } catch (e) {
      console.error(e)
      new NotifyedError(e as Error, 'Не удалось загрузить атрибуты цикла')
      return {}
    }
  }

  private async loadCycles() {
    try {
      const cycles = await this.vue.prototype.$api.query('admin_getCycles')
      return cycles.body
    } catch (e) {
      console.error(e)
      new NotifyedError(e as Error, 'Не удалось загрузить список циклов')
      return []
    }
  }

  public async loadSurveys() {
    const selectedCycle = this.vue.prototype.$storeService.get('getSelectedCycle')
    if (!selectedCycle) return []
    try {
      const surveys = await this.vue.prototype.$api.query(
        'admin_getSurveys',
        {},
        {
          cycleId: selectedCycle.id
        }
      )
      this.$storeService.mutate('setLocalizedAttributes', surveys.body[0]?.localizedAttributes || [])
      return surveys.body || []
    } catch (e) {
      console.error(e)
    }
  }

  private async loadRootFilters() {
    const selectedCycle = this.vue.prototype.$storeService.get('getSelectedCycle')
    if (!selectedCycle) return []

    try {
      const { body: filters } = await this.vue.prototype.$api.query(
        'admin_getFilters',
        {},
        {
          cycleId: selectedCycle.id
        }
      )

      return filters || []
    } catch (e) {
      console.error(e)
    }
  }

  private async loadFeedbackxSurveyIds() {
    const selectedCycle = this.vue.prototype.$storeService.get('getSelectedCycle')
    if (!selectedCycle) return []

    try {
      const { body: filters } = await this.vue.prototype.$api.query(
        'admin_feedbackx_getSurveyIds',
        {},
        {
          cycleId: selectedCycle.id
        }
      )

      return filters || []
    } catch (e) {
      console.error(e)
    }
  }

  public verifyStudy(studies: Study[]) {
    const query = router.currentRoute?.query
    let selectedStudyId = this.selectedStudy.value

    if (query?.studyId !== undefined && query?.studyId !== null) selectedStudyId = query.studyId

    this.$storeService.mutate('setSelectedStudy', selectedStudyId)
    const selectedStudy = studies.find((study) => study!.id === selectedStudyId)
    this.vue.prototype.$authService.tokenService.tokens.study.value = selectedStudy?.token

    if (Object.keys(query ?? {}).length && selectedStudy?.token) {
      this.$storeService.mutate('setStudyToken', selectedStudy.token)
      this.$authService.acceptStudyToken(selectedStudy.token, selectedStudy.id ?? '')
    }

    if (!selectedStudy) {
      return false
    }

    const { clientId } = selectedStudy!
    if (clientId) {
      this.$storeService.mutate('setSelectedClientId', clientId)
    }
    return true
  }

  public async loadWorkData(isInitial?: boolean) {
    await this.vue.prototype.$api.loadSpec()

    const studies = this.loadStudyTokens()
    const clients = this.loadClients()

    if (Object.keys(router.currentRoute?.query ?? {}).length && !isInitial) {
      const query = router.currentRoute?.query

      this.$storeService.mutate('selectCycle', query?.cycleId ?? null)
      this.$storeService.mutate('selectSurvey', query?.surveyId ?? null)
      this.$storeService.mutate('setSelectedClientId', query?.clientId ?? null)
      this.selectedStudy.value = query?.studyId ?? null
    }

    if (this.verifyStudy(await studies)) {
      const locales = await this.getStudyLocales()
      this.$storeService.mutate('setStudyLocales', locales)
      EventBus.register(LOCALES_LOADED)

      const cycles = await this.loadCycles()
      this.vue.prototype.$storeService.mutate('setCyclesList', cycles)
      EventBus.register(CYCLES_LOADED)

      const surveys = await this.loadSurveys()
      this.vue.prototype.$storeService.mutate('setSurveysList', surveys)
      EventBus.register(SURVEYS_LOADED)

      const cycleAttributes = await this.loadCycleAttributes()
      this.vue.prototype.$storeService.mutate('setCycleAttributes', cycleAttributes)
    }

    this.vue.prototype.$storeService.mutate('setStudiesList', await studies)
    EventBus.register(STUDIES_LOADED)
    this.vue.prototype.$storeService.mutate('setClientsList', await clients)
    EventBus.register(CLIENTS_LOADED)
    this.$storeService.mutate('setRootFilters', await this.loadRootFilters())
    if (this.isFeedbackx) {
      const surveys = await this.loadFeedbackxSurveyIds()
      const surveysList = []

      const titleAdapter: any = {
        chooseId: 'Выбор связей',
        evaluateId: 'Оценка',
        verifyId: 'Верификация'
      }

      for (const item in surveys) {
        const survey = {
          id: surveys[item],
          title: titleAdapter[item]
        }

        surveysList.push(survey)
      }

      this.vue.prototype.$storeService.mutate('selectSurvey', surveys.chooseId)
      this.vue.prototype.$storeService.mutate('setSurveysList', surveysList)
    }
  }

  public async getStudyLocales(): Promise<string[]> {
    try {
      const locales = await this.vue.prototype.$api.query('admin_getStudyLocales')
      return locales.body.locales
    } catch (e) {
      return ['ru']
    }
  }

  public async setStudyLocales(): Promise<void> {
    try {
      const studyLocales = this.$storeService.get('getStudyLocales')
      await this.vue.prototype.$api.query(
        'admin_setStudyLocales',
        {},
        {
          locales: studyLocales
        }
      )
      this.$storeService.mutate('setStudyLocales', studyLocales)
      SuccessMessage('Языки сохранены')
    } catch (e) {
      new NotifyedError(e as Error, 'Не удалось сохранить языки опроса')
    }
  }

  public async reloadClients() {
    const clients = this.loadClients()
    this.vue.prototype.$storeService.mutate('setClientsList', await clients)
    EventBus.register(CLIENTS_LOADED)
  }

  public async reloadStudies() {
    const studies = this.loadStudyTokens()
    this.vue.prototype.$storeService.mutate('setStudiesList', await studies)
    EventBus.register(STUDIES_LOADED)
  }

  public async reloadCycles() {
    const cycles = await this.loadCycles()
    this.vue.prototype.$storeService.mutate('setCyclesList', cycles)
    EventBus.register(CYCLES_LOADED)
  }

  public async reloadSurveys() {
    const surveys = await this.loadSurveys()
    this.vue.prototype.$storeService.mutate('setSurveysList', surveys)
    EventBus.register(SURVEYS_LOADED)
  }

  public appendClientsList(newClient: Client) {
    const currentClents: Client[] = this.$storeService.get('clients') || []
    currentClents.push(newClient)
    this.$storeService.mutate('setClientsList', currentClents)
  }

  private pushClientsHistory() {
    const currentClient = this.selectedClient
    const currentHistory = this.clientHistory.value
    const newHistory = currentHistory ?? []
    const includesCurrent = newHistory.find((elem: any) => elem.id === currentClient.id)
    if (!includesCurrent) {
      if (newHistory.length === 3) {
        newHistory.splice(0, 1)
      }
      newHistory.push(currentClient)
    }
    this.clientHistory.value = newHistory
  }

  public async selectStudy(study: Study, fromCreator?: boolean) {
    this.$authService.tokenService.tokens.study!.value = study.token
    this.selectedStudy.value = study.id
    this.$storeService.mutate('setSelectedStudy', study.id)
    this.$storeService.mutate('setSelectedClientId', study.clientId)
    this.pushClientsHistory()
    if (fromCreator) {
      await this.loadWorkData()
      router.push({ name: 'Cycles' })
    } else {
      window.location.href = '/'
    }
  }

  get selectedClientId() {
    return this.$storeService.get('selectedClientId')
  }

  get selectedClient() {
    const clientId = this.$storeService.get('selectedClientId')
    if (!clientId) return null
    const clients = this.$storeService.get('clients')
    return clients.find((client: any) => client.id === clientId) ?? null
  }

  get currentClientsHistory() {
    return this.clientHistory.value
  }

  get selectedStudyObject() {
    return this.$storeService.get('getSelectedStudy')
  }

  get isFeedbackx(): boolean {
    const currentStudyType = this.$storeService.get('getSelectedStudy')?.type || ''
    return currentStudyType === FEEDBACKX
  }

  get currentStudyType() {
    return this.selectedStudyObject?.type
  }

  get currentModeType() {
    return this.$storeService.get('getSettingsMode')
  }

  public updateClientsHistory(updated: Client) {
    const history = this.clientHistory.value
    const searchClient = history.find((cli: Client) => cli.id === updated.id)
    for (const key in updated) {
      if (searchClient) {
        // @ts-ignore
        searchClient[key] = updated[key]
      }
    }
    this.clientHistory.value = history
    this.vue.prototype.$eventBus.register(CLIENTS_HISTORY_UPDATED)
  }

  public registerAndShowAsyncExport() {
    EventBus.register('async-export')
    this.$storeService.mutate('setExportListVisible', true)
  }

  public registerAndShowAsyncImport() {
    EventBus.register('async-import')
    this.$storeService.mutate('setImportListVisible', true)
  }
}
