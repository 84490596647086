/**
 * CookieRepository - репозиторий для хранения данных в
 * куках браузера
 *
 * V2 :: унифицирован под любые куки
 *
 */

export default class CookieRepository {
  public COOKIE_LIFE_TIME: number = process.env.DEV ? 100 : 1 // days
  public cookieName: string

  constructor(cookieName: string, cookieLifetime?: number) {
    this.cookieName = cookieName

    if (cookieLifetime) {
      this.COOKIE_LIFE_TIME = cookieLifetime
    }
  }

  public deleteValue(): void {
    document.cookie = `${this.cookieName}=; Max-Age=-99999999;`
  }

  /**
   *
   * @returns {string|null}
   */
  get value(): any {
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(this.cookieName) === 0) {
        let cookieValue: any = c.substring(this.cookieName.length, c.length)
        if (cookieValue[0] === '=') cookieValue = cookieValue.slice(1, cookieValue.length)
        if (cookieValue.trim() === '') cookieValue = null
        try {
          const returnValue = JSON.parse(cookieValue)
          return returnValue
        } catch (e: any) {
          return cookieValue
        }
      }
    }
    return null
  }

  /**
   *
   * @param value
   */
  set value(value: any) {
    let expires = ''
    const lifetime = this.COOKIE_LIFE_TIME
    const date = new Date()
    date.setTime(date.getTime() + lifetime * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
    const writeValue = JSON.stringify(value)
    document.cookie = `${this.cookieName}=${writeValue}${expires}; samesite=lax; path=/`
  }
}
