import Vue from 'vue'
import VueRouter from 'vue-router'
import routerAuthGuard from '@/router/Guards/AuthGuard'
import { FbxQuestionTypesConfig } from '@/data/FbxSurveySchemas'

Vue.use(VueRouter)

export const usersManagementRoutes = [
  {
    name: 'users-management:roles',
    path: '/users-management/roles',
    component: () =>
      import(/* webpackChunkName: "users-management:roles" */ '@/views/Pages/UsersManagement/UsersManagementRoles.vue'),
    meta: {
      title: 'Настройка ролей'
    }
  },
  {
    name: 'users-management:relations',
    path: '/users-management/relations',
    component: () =>
      import(
        /* webpackChunkName: "users-management:relations" */ '@/views/Pages/UsersManagement/UsersManagementRelations.vue'
      ),
    meta: {
      title: 'Настройка связей'
    }
  },
  {
    name: 'users-management:users-access-fbx',
    path: '/users-management/users-access-fbx',
    component: () =>
      import(
        /* webpackChunkName: "users-management:users-access-fbx" */ '@/views/Pages/UsersManagement/UsersAccessFbx.vue'
      ),
    meta: {
      title: 'Доступы FBX'
    }
  }
]

export const reportConstructorRoutes = [
  {
    name: 'report-constructor',
    path: '/report-constructor',
    component: () =>
      import(/* webpackChunkName: "users-management:roles" */ '@/views/Pages/ReportConstructor/ReportConstructor.vue'),
    meta: {
      title: 'Конструктор отчетов'
    }
  }
]

export const reportIdpRoutes = [
  {
    name: 'idp',
    path: '/idp',
    component: () => import(/* webpackChunkName: "users-management:roles" */ '@/views/Pages/Idp/Idp.vue'),
    meta: {
      title: 'Настройка ИПР'
    }
  },
  {
    name: 'idp-material',
    path: '/idp-material/:id',
    component: () => import(/* webpackChunkName: "idp:material-page" */ '@/views/Pages/Idp/IdpMaterialPage.vue'),
    meta: {
      title: 'Изменить список материалов'
    }
  },
  {
    name: 'idp-envelope',
    path: '/idp-envelope/:id',
    component: () => import(/* webpackChunkName: "idp:envelope-page" */ '@/views/Pages/Idp/IdpEnvelopePage.vue'),
    meta: {
      title: 'Изменить конверт'
    }
  }
]

export const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main-layout" */ '@/views/Layouts/AppLayout/index.vue'),
    children: [
      {
        path: '/',
        alias: '/main',
        name: 'PanelIndex',
        meta: {
          title: 'Панель управления'
        },
        component: () => import(/* webpackChunkName: "panel-index" */ '@/views/Pages/PanelIndex.vue')
      },
      {
        path: '/panel-settings',
        name: 'PanelSettings',
        meta: {
          title: 'Настройка системы'
        },
        component: () => import(/* webpackChunkName: "panel-index" */ '@/views/Pages/PanelSettings.vue')
      },
      {
        path: '/panel-settings/translate',
        name: 'Translate',
        meta: {
          title: 'Таблица переводов'
        },
        component: () => import(/* webpackChunkName: "translate" */ '@/views/Pages/Translate/Translate.vue')
      },
      {
        path: '/panel-settings/qr-generator',
        name: 'QrGenerator',
        meta: {
          title: 'Генератор QR-кодов'
        },
        component: () => import(/* webpackChunkName: "qr-gen" */ '@/views/Pages/QrGenerator/QrGenerator.vue')
      },
      {
        path: '/panel-settings/bench-localization',
        name: 'BenchLocalization',
        meta: {
          title: 'Локализация бенчей'
        },
        component: () => import(/* webpackChunkName: "translate" */ '@/views/Pages/BenchLocalization.vue')
      },
      {
        path: '/panel-settings/industry-edit',
        name: 'IndustryEdit',
        meta: {
          title: 'Редактирование отраслей'
        },
        component: () => import(/* webpackChunkName: "translate" */ '@/views/Pages/IndustryEdit.vue')
      },
      {
        path: '/panel-settings/benchmarks-report',
        name: 'BenchmarksReport',
        meta: {
          title: 'Бенчмарки'
        },
        component: () =>
          import(/* webpackChunkName: "translate" */ '@/views/Pages/BenchmarksReport/BenchmarksReport.vue')
      },
      {
        path: '/clients/list',
        name: 'ClientsEdit',
        meta: {
          title: 'Клиенты'
        },
        component: () => import(/* webpackChunkName: "clients-list" */ '@/views/Pages/ClientsList.vue')
      },
      {
        path: '/client/edit/:clientId',
        name: 'ClientsEditId',
        meta: {
          title: 'Редактирование клиента'
        },
        component: () => import(/* webpackChunkName: "clients-list" */ '@/views/Pages/ClientEdit/ClientEdit.vue')
      },
      {
        path: '/studies/:scope',
        name: 'StudiesList',
        meta: {
          title: 'Исследования'
        },
        component: () => import(/* webpackChunkName: "studies-list" */ '@/views/Pages/Studies.vue')
      },
      {
        path: '/processes',
        name: 'Processes',
        meta: {
          title: 'Процессы'
        },
        component: () => import(/* webpackChunkName: "processes" */ '@/views/Pages/Processes/Processes.vue')
      },
      {
        path: '/criterion',
        name: 'Criterion',
        meta: {
          title: 'Критерии'
        },
        component: () => import(/* webpackChunkName: "criterion" */ '@/views/Pages/Criterion/Criterion.vue')
      },
      {
        path: '/cycles',
        name: 'Cycles',
        meta: {
          title: 'Циклы'
        },
        component: () => import(/* webpackChunkName: "cycles" */ '@/views/Pages/Cycles.vue')
      },
      {
        path: '/studies/edit/:studyId',
        name: 'StudyEdit',
        meta: {
          title: 'Редактирование исследования'
        },
        component: () => import(/* webpackChunkName: "cycles" */ '@/views/Pages/StudyEdit/StudyEdit.vue')
      },
      {
        path: '/cycles/edit/:cycleId',
        name: 'CycleEdit',
        meta: {
          title: 'Редактирование цикла'
        },
        component: () => import(/* webpackChunkName: "cycles" */ '@/views/Pages/CycleEdit/CycleEdit.vue')
      },
      {
        path: '/cycles/list/:cycleId',
        name: 'CycleEditId',
        meta: {
          title: 'Циклы'
        },
        component: () => import(/* webpackChunkName: "cycles" */ '@/views/Pages/Cycles.vue')
      },
      {
        path: '/respondents',
        redirect: '/structure'
      },
      {
        path: '/structure',
        name: 'Respondents',
        meta: {
          title: 'Структура'
        },
        component: () => import(/* webpackChunkName: "respondents" */ '@/views/Pages/StructureEdit/StructureEdit.vue')
      },
      {
        path: '/surveys',
        name: 'Surveys',
        meta: {
          title: 'Опросы'
        },
        component: () => import(/* webpackChunkName: "surveys" */ '@/views/Pages/Surveys.vue')
      },
      {
        path: '/respondents-management',
        name: 'RespondentsSearch',
        meta: {
          title: 'Поиск респондентов'
        },
        component: () => import(/* webpackChunkName: "resp-search" */ '@/views/Pages/RespondentsSearch.vue')
      },
      {
        path: '/evaluations-management',
        name: 'EvaluationsManagement',
        meta: {
          title: 'Управление комментариями'
        },
        component: () => import(/* webpackChunkName: "comments-management" */ '@/views/Pages/EvaluationsManagement.vue')
      },
      {
        path: '/mailing-management',
        name: 'MailingList',
        meta: {
          title: 'Рассылки'
        },
        component: () => import(/* webpackChunkName: "mailing" */ '@/views/Pages/Mailer/MailingList.vue')
      },
      {
        path: '/mailing-management/edit/:mailingId',
        name: 'MailingItem',
        meta: {
          title: 'Редактирование рассылкой'
        },
        component: () => import(/* webpackChunkName: "mailing-edit" */ '@/views/Pages/Mailer/MailingItem.vue')
      },
      {
        path: '/surveys/edit/:surveyId',
        name: 'SurveyEdit',
        meta: {
          title: 'Редактирование опроса'
        },
        component: () => {
          return import(/* webpackChunkName: "survey-edit" */ '@/views/Pages/SurveyEdit/Index.vue')
        }
      },
      {
        path: '/users-access',
        name: 'UsersAccess',
        meta: {
          title: 'Пользователи и доступы'
        },
        component: () => import(/* webpackChunkName: "users-access" */ '@/views/Pages/UsersAccess.vue')
      },
      {
        path: '/target-values',
        name: 'targetValues',
        meta: {
          title: 'Целевые показатели'
        },
        component: () => import(/* webpackChunkName: "target-values" */ '@/views/Pages/TargetValues/TargetValues.vue')
      },
      {
        path: '/slack-accounts',
        name: 'slackAccounts',
        meta: {
          title: 'Аккаунты Slack'
        },
        component: () =>
          import(/* webpackChunkName: "slack-accounts" */ '@/views/Pages/SlackAccounts/SlackAccounts.vue')
      },
      {
        path: '/general-links',
        name: 'GeneralLinks',
        meta: {
          title: 'Общие ссылки'
        },
        component: () => import(/* webpackChunkName: "users-access" */ '@/views/Pages/GeneralLinks/GeneralLinks.vue')
      },
      {
        path: '/general-link/create',
        name: 'GeneralLinksCreate',
        meta: {
          title: 'Создание общей ссылки'
        },
        component: () => import(/* webpackChunkName: "users-access" */ '@/views/Pages/GeneralLinkCreate.vue')
      },
      {
        path: '/general-link/edit/:id',
        name: 'GeneralLinksEdit',
        meta: {
          title: 'Редактирование общей ссылки'
        },
        component: () => import(/* webpackChunkName: "users-access" */ '@/views/Pages/GeneralLinkCreate.vue')
      },
      {
        path: '/response-time-log',
        name: 'ResponseTimeLog',
        meta: {
          title: 'Журнал прохождения'
        },
        component: () => import(/* webpackChunkName: "users-access" */ '@/views/Pages/ResponseTimeLog.vue')
      },
      {
        path: '/survey-research-speed',
        name: 'SurveyResearchSpeed',
        meta: {
          title: 'Скорость прохождения опроса'
        },
        component: () => import(/* webpackChunkName: "users-access" */ '@/views/Pages/SurveyResearchSpeed.vue')
      },
      {
        path: '/plan-mail-settings',
        name: 'PlanMailSettings',
        meta: {
          title: 'Настройка уведомлений'
        },
        component: () => import('@/views/Pages/PlanMailSettings/PlanMailSettings.vue')
      },
      {
        path: '/plan-mail-templates',
        name: 'PlanMailTemplates',
        meta: {
          title: 'Настройка уведомлений'
        },
        component: () => import('@/views/Pages/PlanMailTemplates/PlanMailTemplates.vue')
      },
      {
        path: '/plan-mail-template',
        name: 'PlanMailTemplate',
        meta: {
          title: 'Создать новое письмо'
        },
        component: () => import('@/views/Pages/PlanMailTemplate/PlanMailTemplate.vue')
      },
      {
        path: '/plan-mail-template/edit/:templateId',
        name: 'PlanMailTemplateEdit',
        meta: {
          title: 'Редактировать письмо'
        },
        component: () => import('@/views/Pages/PlanMailTemplate/PlanMailTemplate.vue')
      },
      {
        path: '/users-access/editor/:userId',
        name: 'UserAccessEditor',
        meta: {
          title: 'Редактировать доступ пользователя'
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "user-access-editor" */ '@/views/Pages/AccessEditor/AccessEditor.vue')
      },
      {
        path: '/survey-builder',
        name: 'SurveyBuilder',
        meta: {
          title: 'Вопросы цикла'
        },
        component: () =>
          import(/* webpackChunkName: "survey-builder" */ '@/views/Pages/HappySurveyBuilder/HappySurveyBuilder.vue')
      },
      {
        path: '/action-plan',
        name: 'ActionPlan',
        meta: {
          title: 'План работ'
        },
        component: () => import(/* webpackChunkName: "action-plan" */ '@/views/Pages/ActionPlan/ActionPlan.vue')
      },
      {
        path: '/fbx-survey-builder',
        name: 'FBXSurveyBuilder',
        meta: {
          title: 'Вопросы цикла',
          QuestionTypesConfig: FbxQuestionTypesConfig
        },
        component: () =>
          import(/* webpackChunkName: "survey-builder" */ '@/views/Pages/HappySurveyBuilder/HappySurveyBuilder.vue')
      },
      {
        path: '/theme-tree-editor',
        name: 'ThemeEditor',
        meta: {
          title: 'Редактор дерева тем'
        },
        component: () => import(/* webpackChunkName: "mailing" */ '@/views/Pages/ThemeEditor/ThemeEditor.vue')
      },
      {
        path: '/metric-tree-editor',
        name: 'MetricEditor',
        meta: {
          title: 'Редактор дерева метрик'
        },
        component: () => import(/* webpackChunkName: "mailing" */ '@/views/Pages/MetricEditor/MetricEditor.vue')
      },
      {
        path: '/rules-for-hiding-metrics',
        name: 'RulesMetrics',
        meta: {
          title: 'Правила скрытия метрик'
        },
        component: () => import(/* webpackChunkName: "mailing" */ '@/views/Pages/RulesMetrics/RulesMetrics.vue')
      },
      {
        path: '/report-builder',
        name: 'ReportBuilder',
        meta: {
          title: 'Конструктор отчетов'
        },
        component: () =>
          import(/* webpackChunkName: "mailing" */ '@/views/Pages/FBX/ReportBuilderNew/ReportBuilderNew.vue')
      },
      {
        path: '/fbx-cycles/edit/:cycleId',
        name: 'FBXCycleEdit',
        meta: {
          title: 'Настройки цикла'
        },
        component: () => import(/* webpackChunkName: "cycles" */ '@/views/Pages/FBX/CycleEdit/CycleEdit.vue')
      },
      {
        path: '/hidden-settings',
        name: 'HiddenSettings',
        meta: {
          title: 'Скрытые настройки'
        },
        component: () =>
          import(/* webpackChunkName: "hidden-settings" */ '@/views/Pages/HiddenSettings/HiddenSettings.vue')
      },
      {
        path: '/fbx-peer-selection',
        name: 'FbxPeerSelection',
        meta: {
          title: 'Настройки этапа выбора'
        },
        component: () => import('@/views/Pages/FBX/FbxPeerSelection.vue')
      },
      {
        path: '/fbx-survey',
        name: 'FbxSurvey',
        meta: {
          title: 'Настройки опроса'
        },
        component: () => import(/* webpackChunkName: "comments-management" */ '@/views/Pages/FBX/FbxSurvey.vue')
      },
      {
        path: '/users-management',
        name: 'users-management',
        redirect: {
          name: 'users-management:role'
        }
      },
      {
        name: 'department-functions',
        path: '/department-functions',
        redirect: {
          name: 'CompaniesTab'
        },
        component: () =>
          import(
            /* webpackChunkName: "users-management:department-functions" */ '@/views/Pages/DepartmentFunctions/DepartmentFunctions.vue'
          ),
        children: [
          {
            path: '/department-functions/companies',
            name: 'CompaniesTab',
            meta: {
              title: 'Настройки функций отдела'
            },
            component: () =>
              import(
                /* webpackChunkName: "users-management:department-functions/companies" */ '@/views/Pages/DepartmentFunctions/components/CompaniesTab/СompaniesTab.vue'
              )
          },
          {
            path: '/department-functions/functions',
            name: 'FunctionsTab',
            meta: {
              title: 'Настройки функций отдела'
            },
            component: () =>
              import(
                /* webpackChunkName: "users-management:department-functions/functions" */ '@/views/Pages/DepartmentFunctions/components/FunctionsTab/FunctionsTab.vue'
              )
          }
        ],
        meta: {
          title: 'Настройки функций отдела'
        }
      },

      ...usersManagementRoutes,
      ...reportConstructorRoutes,
      ...reportIdpRoutes
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Вход'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Layouts/LoginLayout/index.vue')
  },
  {
    path: '*',
    name: '404Error',
    meta: {
      title: 'Страница не найдена'
    },
    component: () => import(/* webpackChunkName: "404" */ '@/views/Pages/Errors/404.vue')
  }
]

export const router = new VueRouter({
  routes
})

router.beforeEach(routerAuthGuard)

export default router
